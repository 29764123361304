<template>
  <section>
    <div v-if="loading" class="loadingBox">
      <v-progress-circular
        indeterminate
        size="100"
        color="blue"
      ></v-progress-circular>
    </div>
    <div v-if="!loading">
      <LightBox
        v-if="medias.length > 0"
        :media="medias"
        :startAt="index"
        :showLightBox="visible"
        ref="ligthbox"
      ></LightBox>
      <div class="gigBox">
        <div class="mt-5">
          <h2>
            {{ gig.title }}
            <span class="revision">{{ gigApplied.gigStatus }}</span>
          </h2>
          <p>
            {{ gig.description }}
          </p>
          <div v-if="medias.length == 0 && gigApplied.gigStatus == 'approved'">
            <small>Please upload your content here</small>
            <div>
              <vue-dropzone
                class="mb-2"
                ref="myVueDropzone"
                id="dropzone"
                :options="dropzoneOptions"
              ></vue-dropzone>
              <v-btn
                class="mt-2 mb-5"
                @click="uploadContent"
                v-if="!submitLoading"
                >Upload</v-btn
              >
              <v-btn class="mt-2 mb-5" loading v-if="submitLoading"
                >Upload</v-btn
              >
            </div>
          </div>

          <div class="files" v-if="medias.length > 0">
            <div
              class="post"
              v-for="(n, i) in medias"
              :key="i + 'media'"
              @click="showImages(i)"
            >
              <img :src="n.thumb" alt="" v-if="n.type == 'image'" />

              <video width="320" height="240" controls v-if="n.type == 'video'">
                <source :src="n.thumb" type="video/mp4" />
                <source :src="n.thumb" type="video/ogg" />
                Your browser does not support the video tag.
              </video>

              <v-icon v-if="n.approveStatus"> mdi-check </v-icon>
            </div>
          </div>
        </div>

        <div v-if="threads.length > 0">
          <h3 class="mt-10">Threads</h3>
          <div v-for="(dat, index) in threads" :key="index + 'thread'">
            <div class="pb-10">
              <GigThread
                :thread="dat"
                :threadIndex="index + 1"
                @submittedThread="reFetch"
              />
            </div>
          </div>
        </div>
        <v-btn
          @click="popBoxToConfirm"
          v-if="gigApplied.gigStatus == 'accepted'"
          >Send For Approval</v-btn
        >
        <div class="mt-5" v-if="gigApplied.gigStatus == 'acceptedforposting'">
          <h3 class="mb-2">Post your gig</h3>

          <div class="formBox postGig">
            <v-text-field
              v-model="postName"
              outlined
              placeholder="Post name"
            ></v-text-field>
            <div class="applyGig">
              <v-textarea
                v-model="description"
                outlined
                placeholder="Description"
              ></v-textarea>
            </div>
            <div class="mrg-top-minus">
              <AddTag />
            </div>
          </div>
          <v-btn v-if="!loadingSubmit" @click="postContentOfPost">Submit</v-btn>
          <v-btn v-if="loadingSubmit" loading></v-btn>
        </div>
      </div>
    </div>
    <div class="confirmBox" v-if="approveBox">
      <div class="confirmBoxContent">
        <h3>Are you sure you want to send for approval ?</h3>
        <ul v-if="!loadingApproval">
          <li @click="postGigAct">Yes</li>
          <li @click="popBoxToConfirm">No</li>
        </ul>
        <v-progress-circular
          indeterminate
          v-if="loadingApproval"
          class="mt-4"
        ></v-progress-circular>
      </div>
    </div>
  </section>
</template>

<script>
import GigThread from "@/components/gig/Thread";
import AddTag from "@/components/user/AddTag";
import { mapActions, mapGetters } from "vuex";
import LightBox from "vue-image-lightbox";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  name: "GigAppliedSingle",
  data: () => ({
    postGig: false,
    postName: undefined,
    description: undefined,
    uploadSuccess: false,
    tag: undefined,
    tags: [],
    index: undefined,
    visible: false,
    contentUpload: false,
    images: undefined,
    medias: [],
    videos: undefined,
    dropzoneOptions: {
      url: "https://httpbin.org/post",
      thumbnailWidth: 150,
      maxFilesize: 10,
      headers: { "My-Awesome-Header": "header value" },
      addRemoveLinks: true,
      dictDefaultMessage: "Click to upload files",
    },
    gig: undefined,
    gigApplied: undefined,
    loading: true,
    threads: [],
    loadingSubmit: false,
    loadingApproval: false,
    approveBox: false,
    submitLoading: false,
  }),
  components: {
    AddTag,
    LightBox,
    vueDropzone: vue2Dropzone,
    GigThread,
  },
  computed: {
    ...mapGetters(["allTags", "allGig"]),
  },
  methods: {
    ...mapActions([
      "getGigApplied",
      "postToPost",
      "postForApproval",
      "postGigContent",
    ]),
    acceptAct() {
      this.apply = !this.apply;
    },
    async uploadAct() {
      this.uploadSuccess = !this.uploadSuccess;
    },
    gigStatusAct(data) {
      this.gigStatus = data;
    },
    async postGigAct() {
      this.loadingApproval = true;
      await this.postForApproval(this.gigApplied._id);
      this.loadingApproval = false;
      this.gigApplied = this.allGig.results;
      this.approveBox = false;
      this.reFetch();
    },
    showImages(index) {
      this.index = index;
      this.visible = true;
      this.$refs.ligthbox.showImage(index);
    },
    async uploadContent() {
      this.submitLoading = true;
      this.images = [];
      this.videos = [];
      let fileBundles = this.$refs.myVueDropzone.getAcceptedFiles();
      fileBundles.forEach((ele) => {
        if (ele.type.split("/")[0] == "image") {
          this.images.push(ele);
        }

        if (ele.type.split("/")[0] == "video") {
          this.videos.push(ele);
        }
      });
      let formData = new FormData();
      if (this.images != undefined) {
        this.images.forEach((ele) => {
          formData.append("images", ele);
        });
      }

      if (this.videos != undefined) {
        this.videos.forEach((ele) => {
          formData.append("videos", ele);
        });
      }
      formData.append("id", this.$route.params.id);
      await this.postGigContent(formData);
      this.contentUpload = true;
      this.submitLoading = false;
      this.reFetch();
    },
    async reFetch() {
      this.loading = true;
      await this.getGigApplied(this.$route.params.id);
      this.gigApplied = this.allGig.results;
      this.gig = this.allGig.gig;
      this.threads = this.allGig.threads;

      if (this.gigApplied.images.length > 0) {
        this.gigApplied.images.forEach((ele) => {
          this.medias.push({
            type: "image",
            thumb: ele.fileLocation,
            src: ele.fileLocation,
            srcset: ele.fileLocation,
            approveStatus: ele.gigApproveStatus,
          });
        });
      }

      if (this.gigApplied.videos.length > 0) {
        this.gigApplied.videos.forEach((ele) => {
          this.medias.push({
            type: "video",
            thumb: ele.fileLocation,
            src: ele.fileLocation,
            srcset: ele.fileLocation,
            approveStatus: ele.gigApproveStatus,
          });
        });
      }
      this.loading = false;
    },
    popBoxToConfirm() {
      this.approveBox = !this.approveBox;
    },
    async postContentOfPost() {
      this.loadingSubmit = true;
      await this.postToPost({
        tags: JSON.stringify(this.allTags),
        id: this.gig._id,
        postName: this.postName,
        description: this.description,
      });
      this.$router.push("/gigApplied");
      this.loadingSubmit = false;
    },
  },
  async created() {
    await this.getGigApplied(this.$route.params.id);
    this.gigApplied = this.allGig.results;
    this.gig = this.allGig.gig;
    this.threads = this.allGig.threads;

    if (this.gigApplied.images.length > 0) {
      this.gigApplied.images.forEach((ele) => {
        this.medias.push({
          type: "image",
          thumb: ele.fileLocation,
          src: ele.fileLocation,
          srcset: ele.fileLocation,
          approveStatus: ele.gigApproveStatus,
        });
      });
    }

    if (this.gigApplied.videos.length > 0) {
      this.gigApplied.videos.forEach((ele) => {
        this.medias.push({
          type: "video",
          thumb: ele.fileLocation,
          src: ele.fileLocation,
          srcset: ele.fileLocation,
          approveStatus: ele.gigApproveStatus,
        });
      });
    }

    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.buttonList {
  padding-left: 0;
  margin: 25px 0 40px;

  li {
    margin-left: 0;
    .v-btn {
      width: 180px;
      min-height: 50px;
      background: #725ee3;
      color: #fff;
    }
  }
}

.btnWidth {
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 5px;
    width: 250px !important;
    min-height: 50px;
  }
  .waitingBtn {
    background: orange !important;
  }
  .rejectBtn {
    background: red !important;
  }
  .approveBtn {
    background: green !important;
  }
}
h2 {
  font-weight: 700;
  text-transform: capitalize;
  margin-top: 20px;
}
p {
  margin-bottom: 15px !important;
}
header {
  box-shadow: 0 3px 5px #efefef;
  padding: 15px 0 10px;
  margin: 0;
}
.marginrow {
  margin: -15px -15px 0;
}
.tab {
  border: 1px solid #cac6c6;
  display: inline-block;
  padding: 3px 10px;
  font-size: 14px;
  border-radius: 30px;
}
.files {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  .post {
    margin: 3px;
    width: 23%;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: flex-start;
    border: 1px solid #d8d8d8;
    img {
      padding: 10px 0;
      width: 100%;
      object-fit: cover;
    }
    .v-icon {
      left: 10px;
      top: 10px;
      padding: 5px;
      border-radius: 50%;
      position: absolute;
      height: 20px;
      width: 20px;
      font-size: 12px;
      background: rgb(1, 195, 1);
      color: #fff;
    }
  }
}
.active {
  background: green !important;
  color: #fff;
}
.filterButtons {
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  display: flex;
  justify-content: space-between;
  background: #f0f0f0;
  margin: 0 -15px;
  padding: 25px 15px;
  .v-btn {
    width: 32%;
    min-height: 50px;
    background: transparent;
    box-shadow: none;
    border: 1px solid #bcb8b8;
    background: #fff;
    .v-icon {
      margin-right: 10px;
    }
  }
}
.revisionBox {
  border: 1px solid #efefef;
  margin-top: 30px;
  padding: 10px;
  border-right: 0;
  border-left: 0;
  color: #725ee3;
}
.gigBox {
  border-bottom: 1px solid #8d8686;
  &:last-of-type {
    border: none;
  }
}
.rejectAct {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: column;
  .v-btn {
    background: transparent;
    box-shadow: none;
    border: 1px solid #d8d8d8;
    text-transform: capitalize;
    margin-top: 20px;
  }
}
.formBox {
  width: 100%;
  margin-top: 20px;
  margin-bottom: -20px;
}
h2 {
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-weight: 400;
    font-size: 15px;
    color: #fff;
    padding: 5px 15px;
    border-radius: 30px;
    text-transform: capitalize;
  }
  .revision {
    background: orange;
  }
  .accepted {
    background: green;
  }
  .notaccepteed {
    background: red;
  }
}
.v-btn {
  background: #725ee3 !important;
  border-radius: 30px;
  color: #fff;
  width: 200px;
  min-height: 50px;
}
.loadMore {
  color: #000;
  background: #fff !important;
  min-height: 40px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.loadingBox {
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
  padding: 100px 0;
}
.confirmBox {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  .confirmBoxContent {
    height: 200px;
    width: 450px;
    background: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;
    ul {
      margin-top: 20px;
      li {
        padding: 8px 20px;
        background: #efefef;
        border-radius: 5px;
        cursor: pointer;
        &:first-of-type {
          background: #725ee3;
          color: #fff;
        }
      }
    }
  }
}
</style>