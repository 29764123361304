<template>
  <section>
    <v-container class="container-custom-lg">
      <v-row>
        <v-col class="text-center">
          <img src="@/assets/images/logoheader.png" alt="" />
          <h1 class="text-h4 font-weight-bold my-10">
            You are not authorized to access this page
          </h1>
         <router-link to="/gig">
            <v-btn color="primary" class="mt-5 px-10 py-6" dark>Go Back</v-btn>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "GoingBack",
};
</script>

<style lang="scss" scoped>
.container-custom-lg {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>